import React, { useRef, useState } from 'react';
import './Home.css';

function Home() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    comments: ''
  });
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    phone: '',
    comments: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Full Name Validation
    if (!formData.fullName) {
      newErrors.fullName = 'Full Name is required';
      valid = false;
    }

    // Email Validation
    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
      valid = false;
    }

    // Phone Validation
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid 10-digit phone number';
      valid = false;
    }

    // Comments Validation
    if (!formData.comments) {
      newErrors.comments = 'Comments are required';
      valid = false;
    }

    setErrors(newErrors);  // Update the errors state
    return valid;
  };

  const homeRef = useRef(null);
  const projectsRef = useRef(null);
  const aboutRef = useRef(null);
  const scrollToSection = (ref) => {
    const targetPosition = ref.current.offsetTop; // Target section's offset position
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {/* Header Bar */}
      <header className="header-bar">
        <nav>
          <ul className="nav-buttons">
            <li>
              <button onClick={() => scrollToSection(homeRef)}>Home</button>
            </li>
            <li>
              <button onClick={() => scrollToSection(projectsRef)}>Projects</button>
            </li>
            <li>
              <button onClick={() => scrollToSection(aboutRef)}>About</button>
            </li>
          </ul>
        </nav>
      </header>

      {/* Sections */}
      <div
        className="section"
        ref={homeRef}
        id="home"
        style={{
          backgroundImage: 'url("/image/home-bg.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }}
      >
        {/* Logo above the text */}
        <img
          src="/image/icon.jpg"
          alt="Logo"
          className="home-logo"
          style={{
            width: '7%',
            height: 'auto',
            display: 'block',
            marginRight: '10px',
            paddingTop: '8px',
          }}
        />
        <h1 className='nistronheader'>Nistron Solutions</h1>
        <div
    className="home-content-box"
    style={{
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '600px',
      color: '#fff',
      marginLeft:'20px'
    }}
  >
    <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Welcome to Nistron Solutions</h2>
    <p style={{ fontSize: '1rem', lineHeight: '1.5' }} className='intopara'>
      At Nistron Solutions, innovation meets simplicity. We are a forward-thinking software company dedicated to
      enhancing everyday experiences through technology. Our mission is to improve the quality of life for everyone by
      addressing real-world challenges with cutting-edge solutions.
    </p>
  </div>
      </div>

      <div
  className="section"
  ref={projectsRef}
  id="projects"
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#f5f5f5', // Light background for contrast
    color: '#333', // Dark text for readability
    padding: '50px 20px',
    height: '100vh'
  }}
>
  <div className='project-content'>
  <h1 style={{ fontSize: '2.5rem', marginBottom: '20px' }}>Our Projects</h1>

  {/* Content Box */}
  <div
    className="projects-content-box"
    style={{
      backgroundColor: '#fff', // White background for the box
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
      maxWidth: '800px',
      color: '#333',
    }}
  >
     {/* Logo above the text */}
     <img
          src="/image/parkmycar.jpg"
          alt="Logo"
          className="home-logo"
          style={{
            width: '100px',
            height: 'auto',
            display: 'block',
            marginRight: '10px',
            paddingTop: '8px',
            paddingLeft: '44%'
          }}
        />
    <h2>ParkMyCar</h2>
    <p style={{ fontSize: '1rem', lineHeight: '1.5', marginBottom: '10px' }}>
      ParkMyCar is our flagship product, designed to revolutionize urban parking. This smart parking solution helps
      users effortlessly find and book parking spaces, pay securely online, and even access additional services like EV
      charging, car washing, E-Scooter rentals etc
    </p>
    <ul style={{ textAlign: 'left', marginLeft: '20px' }} className='ulProject'>
      <li> 
        <strong>Reduce Traffic Congestion:</strong> Minimize the hassle of finding parking spots.
      </li>
      <li>
        <strong>Save Time and Effort:</strong> Navigate busy areas with ease through efficient parking solutions.
      </li>
      <li>
        <strong>Support Sustainable Mobility:</strong> Encourage eco-friendly practices like EV usage.
      </li>
    </ul>
    <p style={{ fontSize: '1rem', lineHeight: '1.5', marginTop: '10px' }}>
      Discover how ParkMyCar is transforming the way cities move and park!
    </p>
  </div>
  </div>
</div>

<div
  className="section"
  ref={aboutRef}
  id="about"
  style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(203 205 209)', // Light blue background for a welcoming feel
    color: '#333',
    padding: '50px 20px',
    height: '100vh', // Full screen height
  }}
>
  {/* Content Box - Left Half */}
  <div
    className="about-content-box"
    style={{
      flex: '1',
      backgroundColor: '#fff', // White background for the box
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
      maxWidth: '600px',
      marginRight: '20px',
      color: '#333',
      height: '46%'
    }}
  >
    <h1 style={{ fontSize: '2.5rem', marginBottom: '20px' }}>About Nistron Solutions</h1>
    <p style={{ fontSize: '1rem', lineHeight: '1.5', marginBottom: '10px' }}>
      Our team combines expertise, creativity, and a deep understanding of user needs to create solutions that make a
      difference. With a focus on quality and innovation, our goal is to make technology an ally in improving everyday
      lives.
    </p>
    <ul style={{ textAlign: 'left', marginLeft: '20px' }}>
      <li>
        <strong>Our Vision:</strong> To be a leader in delivering impactful technology solutions.
      </li>
      <li>
        <strong>Our Mission:</strong> To simplify and enhance daily life through smart, user-friendly software
        products.
      </li>
    </ul>
    <p style={{ fontSize: '1rem', lineHeight: '1.5', marginTop: '10px' }}>
      Nistron Solutions is where challenges meet solutions—and we’re just getting started!
    </p>
  </div>
  <div
  className="contact-us"
  style={{
    flex: '1',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    maxWidth: '600px',
  }}
>
  <h2 style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Contact Us</h2>
  <p>📍 7/67/F, JANNATH, MANNAR PADAM, FEROKE, KOZHIKODE, KERALA - 673631</p>
  <p>📧 nistronsolutions@gmail.com</p>
  <p>📞 +91 9747904746</p>
  </div>
  {/* Enquiry Form - Right Half */}
 {/* Enquiry Form - Right Half */}
<div
  className="enquiry-form"
  style={{
    display:'none',
    flex: '1',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    maxWidth: '600px',
  }}
>
  <h2 style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Submit an Enquiry</h2>
  <form 
  onSubmit={(e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop the form submission if validation fails
    }

    setIsSubmitted(true);
     // Prevents the default form submission behavior
    // Add your form submission logic here, like sending data to a server
    setTimeout(() => setIsSubmitted(false), 3000);
  }}
>
    {/* Full Name and Email - Same Row */}
    <div style={{ display: 'flex', gap: '20px', marginBottom: '15px' }}>
      <div style={{ flex: 1 }}>
        <input
          type="text"
          id="fullName"
          name="fullName"
          placeholder="Enter your full name"
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        />
        {errors.fullName && (
            <span
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                color: 'red',
                fontSize: '12px',
                marginTop: '5px',
              }}
            >
              {errors.fullName}
            </span>
          )}
      </div>
      <div style={{ flex: 1 }}>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter your email"
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            width: '85%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        />
        {errors.email && <span style={{ color: 'red', fontSize:'10px'  }}>{errors.email}</span>}
      </div>
    </div>

    {/* Phone Field */}
    <div style={{ marginBottom: '15px', width: '50%' }}>
      <input
        type="tel"
        id="phone"
        name="phone"
        placeholder="Enter your phone number"
        style={{
          width: '100%',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '5px',
        }}
      />
      {errors.phone && <span style={{ color: 'red', fontSize:'10px'  }}>{errors.phone}</span>}
    </div>

    {/* Comments Field */}
    <div style={{ marginBottom: '15px' }}>
      <textarea
        id="comments"
        name="comments"
        rows="4"
        placeholder="Enter your comments"
        style={{
          width: '96%',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '5px',
        }}
      >
        
      </textarea>
      {errors.comments && <span style={{ color: 'red', fontSize:'10px'  }}>{errors.comments}</span>}
    </div>

    {/* Submit Button */}
    <button
      type="submit"
      style={{
        marginLeft:'60px',
        backgroundColor: '#007bff',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
    >
      Submit
    </button> <span
            style={{
              color: '#787373',
              fontWeight: 'bold',
              visibility: isSubmitted ? 'visible' : 'hidden',
            }}
          >
            Submitted
          </span>
  </form>
</div>

</div>

    </div>
  );
}

export default Home;
