import logo from './logo.svg';
import './App.css';
import Home from './Home.js';

function App() {
  return (
    <div>
<Home />
    </div>
  );
}
export default App;
